import React from 'react'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import Image from '~components/Image'
import { DarrSmall, PlusCircle } from '../components/Svg'
import SanityLink from '~components/SanityLink'

export const breakpoints =  {
	mobile: '@media (max-width: 768px)',
	tablet: '@media (max-width: 1024px)',
	desktop: '@media (max-width: 1230px)',
	desktopUp: '@media (min-width: 1025px)',
	mobileFilter: '@media (max-width: 1500px)'
}

export const mobile = breakpoints.mobile
export const tablet = breakpoints.tablet
export const desktop = breakpoints.desktop
export const desktopUp = breakpoints.desktopUp


const GlobalStyles = () => (
	<>
		<Global styles={reset} />
		<Global styles={styles} />
	</>
)

const styles = css`

  /* Animations */
  @keyframes shimmer {
		0%{background-position: 0% 50%}
    100%{background-position: 100% 50%}
  }

  body {
		font-family: 'Monument Grotesk', sans-serif;
		font-size: 15px;
		line-height: 21px;
		background-color: var(--beige);
    ${tablet} {
      font-size: 14px;
      line-height: 20px;
		}
  }

  :root{
    --white: #ffffff;
		--black: #000000;
		--beige: #E3E1DB;
		--gold: #A57631;
		--red: #b13636;
    --xxs: 5px;
    --xs: 10px;
    --s: 20px;
    --m: 30px;
    --l: 40px;
    --xl: 50px;
    --xxl: 80px;
    --xxxl: 100px;
    --xxxxl: 150px;
  }

  ${tablet}{
    :root{
      --xxs: 3px;
      --xs: 7px;
      --s: 15px;
      --m: 20px;
      --l: 30px;
      --xl: 35px;
      --xxl: 40px;
      --xxxl: 60px;
      --xxxxl: 120px;
    }
  }

  p{
    margin-bottom: 0.5em;
    &:last-child{
      margin-bottom: 0;
    }
  }

	.large {
		font-family: "Fleuron", sans-serif;
		font-size: 48px;
		line-height: 55px;
		text-transform: uppercase;
    ${tablet} {
      font-size: 28px;
      line-height: 38px;
		}
		&.numbers {
			font-size: 46px;
			line-height: 55px;
			${tablet} {
				font-size: 28px;
				line-height: 45px;
			}
		}
	}

  h1,
  .h1 {
		font-family: "Fleuron", sans-serif;
    font-size: 28px;
		line-height: 30px;
		text-transform: uppercase;
		letter-spacing: 0;
    ${tablet} {
      font-size: 19px;
      line-height: 25px;
		}
		&.numbers {
			font-size: 28px;
			line-height: 30px;
			${tablet} {
				font-size: 19px;
				line-height: 25px;
			}
		}
	}
	h2,
  .h2 {
		font-family: "Fleuron", sans-serif;
    font-size: 16px;
		line-height: 21px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
    ${tablet} {
      font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.03em;
    }
	}
	h3,
  .h3 {
		font-family: "Fleuron", sans-serif;
    font-size: 14px;
		line-height: 16px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
    ${tablet} {
      font-size: 12px;
			line-height: 14px;
			letter-spacing: 0.03em;
		}
	}
	h4,
  .h4 {
		font-family: "Fleuron", sans-serif;
    font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
    ${tablet} {
      font-size: 10px;
			line-height: 14px;
			letter-spacing: 0.05em;
    }
	}
	.numbers {
		font-family: "Monument Grotesk Semi Mono", sans-serif;
	}

	input, textarea{
		border: none;
		border-bottom: 1px solid var(--black);
		width: 100%;
		font-family: inherit;
		box-sizing: border-box;
		background: none;
		font-family: inherit;
    font-size: inherit;
		line-height: inherit;
		letter-spacing: inherit;
		text-transform: inherit;
		padding: var(--s) 0;
		margin-bottom: var(--m);
		border-radius: 0;
		-webkit-appearance: none;
		&::placeholder{
			color: var(--black);
		}
		&:focus{
			border-bottom-style: dashed;
			outline: none;
		}
	}

	textarea {
		resize: none;
		border: 1px solid var(--black);
		padding: var(--s);
		&:focus{
			border-style: dashed;
			outline: none;
		}
	}
`

export const OptionSummary = styled.div`
	display: flex;
	align-items: center;
	margin-right: 1em;
	&:last-child{
		margin-right: 0;
	}
`

export const OptionSummaryButton = OptionSummary.withComponent('button')

export const VariantIcon = styled(Image)`
	width: 10px;
	height: 10px;
	border-radius: 100%;
	overflow: hidden;
	margin-right: var(--xxs);
	transform: translateY(-1px);
`
export const NavBar = styled.div`
	display: grid;
	grid-template-columns: max-content 1fr max-content;
	align-items: center;
	position: relative;
	transition: border-color 0.3s;
	border-bottom: 1px solid var(--black);
	${mobile}{
		grid-template-columns: 1fr max-content;
		border-bottom: none;
	}
`
export const NavButton = styled.button`
	display: flex;
`
export const NavHeading = styled.h3`
	padding: var(--s) 0 var(--s) var(--m);
	margin-right: var(--s);
`
export const TopicsIcon = styled(PlusCircle)`
	margin-left: var(--xs);
	width: 13px;
	height: 13px;	
	transition: transform 0.3s;
`
export const NavLinks = styled.div`
	padding: var(--s) var(--m);
	display: flex;
	border-right: 1px solid var(--black);
	${mobile}{
		display: none;
	}
`
export const NavLink = styled.h3`
	margin-right: var(--xl);
	cursor: pointer;	
	${mobile}{
		margin-right: 0;
		width: 100%;
		margin-bottom: var(--l);
		&:last-of-type{
			margin-bottom: 0;
		}
	}
`
export const OrderBy = styled.button`
	padding: var(--s) var(--m);
	display: flex;
	align-self: end;
`
export const SortDropDown = styled.div`
	grid-column: span 3;
	margin-bottom: -1px;
	border-top: 1px solid var(--black);
`
export const Sort = styled.div`
	padding: var(--s) var(--m);
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	${mobile}{
		padding: var(--m);
	}
`
export const SortButton = styled.button`
	margin-left: var(--xl);
	&:first-of-type{
		margin-left: 0px;
	}
	${mobile}{
		margin-left: 0px;
		margin-bottom: var(--l);
		width: 100%;
		text-align: left;
		&:last-of-type{
			margin-bottom: 0px;
		}
	}
`
export const MobileDropDown = styled.div`
	border-top: 1px solid var(--black);
	border-bottom: 1px solid var(--black);
	background: var(--beige);
`
export const MobileNav = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	padding: var(--m);

`
export const DownArrow = styled(DarrSmall)`
	margin-left: var(--xs);
	width: 14px;
	height: 14px;
	transition: transform 0.3s;
	${mobile}{
		width: 12px;
    height: 12px;
	}
`
export const NestedUnderlineLink = styled(SanityLink)`
		>div{
		position: relative;
		&:after{
			content: '';
			border-bottom: 0.11em solid var(--black);
			opacity: 0;
			transition: opacity 0.3s;
			position: absolute;
			left: 0;
			right: 0;
			bottom: -0.09em;
		}
	}
	&:hover{
		>div {
			&:after{
				opacity: 1;
			}
		}
	}
`
export const UnderlineLink = styled(SanityLink)`
	position: relative;
	&:after{
		content: '';
		border-bottom: 0.11em solid var(--black);
		opacity: 0;
		transition: opacity 0.3s;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0em;
	}
	&:hover{
		&:after{
			opacity: 1;
		}
	}
`

const reset = css`

  body {
    margin: 0px;
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
		font-size: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ul, ol, blockquote {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0.5em 0;
  }

  a {
    color: inherit;
    text-decoration: none;
		/* safari */
		&:active{
			color: var(--black);
		}
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
		overflow-anchor: none;
  }

  button{
    -webkit-appearance: none;
    border: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
		/* safari */
		&:active{
			color: var(--black);
		}
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 500px white inset !important;
    box-shadow: 0 0 0 500px white inset !important;
  }

	/* Prevent safari blue links */
	button, option {
    color: inherit;
  }
`

export default GlobalStyles
